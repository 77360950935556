import $ from 'jquery'
import 'select2'
import 'select2/dist/css/select2.css'

window.addEventListener('turbolinks:load', () => {
    let user = $(".users-autocomplete");
    user.on("select2:select", function (e) {
        let resourcesField = document.getElementById('resources-field');
        let buttonField = document.getElementById('button-field');

        if (resourcesField) {
            resourcesField.style.display = 'block';
        }

        if (buttonField) {
            buttonField.style.display = 'block';
        }
    });
    const searchUrl = $("#user_manager_search_url").val();
    user.each(function(index) {
        const id = `user_id_${index}`;
        $(this).attr("id", id);

        $(`#${id}`).select2({
            ajax: {
                type: "GET",
                dataType: "JSON",
                delay: 500,
                url: function(params) {
                    return `${searchUrl}?filter_not_assoc=${$("#notAssociateId").val()}&filter_assoc=${$("#associateId").val()}`;
                },
                //minimumInputLength: 3,
                data: function (params) {
                    //agrego la a x default asi muestra el listado completo,
                    // TODO: buscar una forma mas elegante de resolver esto.
                    var query = {
                        term: params.term || '%'
                    }
                    return query;
                },
                processResults: function (data) {
                    return {
                        results: data.results
                    };
                }
            }
        });
    });
});

