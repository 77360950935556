import $ from 'jquery'
import 'select2'
import 'select2/dist/css/select2.css'

// AA: cambie el evento onload de DOMContentLoaded a turbolinks:load
// porque por algun motivo con DOMContentLoaded no se volvia a ejecutar
// si la pagina ya habia sido visitada

window.addEventListener('turbolinks:load', () => {
    let notAssociate = $(".not-associates-autocomplete");
    let users = $(".users-autocomplete");
    notAssociate.on("select2:select", function (e) {
        $("#notAssociateId").val(e.params.data['id']);
        users.val(null).trigger('change');
        let userField = document.getElementById('user-field');
        if (userField) {
            userField.style.display = 'block';
        }
    });

    const searchUrl = $("#not_associates_search_url").val();
    console.log(searchUrl)

    notAssociate.each(function(index) {
        const id = `not_associate_id_${index}`;
        $(this).attr("id", id);

        $(`#${id}`).select2({
            ajax: {
                type: "GET",
                dataType: "JSON",
                delay: 500,
                url: searchUrl,
                //minimumInputLength: 3,
                data: function (params) {
                    //agrego la a x default asi muestra el listado completo,
                    // TODO: buscar una forma mas elegante de resolver esto.
                    var query = {
                        term: params.term || '%'
                    }
                    return query;
                },
                processResults: function (data) {
                    return {
                        results: data.results
                    };
                }
            }
        });
    });
});

