import $ from 'jquery'
import 'select2'
import 'select2/dist/css/select2.css'

// AA: cambie el evento onload de DOMContentLoaded a turbolinks:load 
// porque por algun motivo con DOMContentLoaded no se volvia a ejecutar 
// si la pagina ya habia sido visitada

window.addEventListener('turbolinks:load', () => {
  const searchUrl = $("#currencies_search_url").val();

  $(".currencies-autocomplete").each(function(index) {
    const id = `currency_id_${index}`;
    $(this).attr("id", id);

    $(`#${id}`).select2({
      ajax: {
        type: "GET",
        dataType: "JSON",
        delay: 500,
        url: searchUrl,
        //minimumInputLength: 3,
        data: function (params) {
          //agrego la a x default asi muestra el listado completo, 
          // TODO: buscar una forma mas elegante de resolver esto.
          var query = {
            term: params.term || '%' 
          }
          return query;
        },
        processResults: function (data) {
          return {
            results: data.results
          };
        }
      }
    });
  });
});

